import {FC, ReactNode, useEffect, useState} from 'react'
import {ReactComponent as YellowIcon} from 'assets/images/svg-forms/yellow.svg'
import {ReactComponent as BlueIcon} from 'assets/images/svg-forms/blue.svg'
import {ReactComponent as PinkIcon} from 'assets/images/svg-forms/pink.svg'
import {ReactComponent as PurpleIcon} from 'assets/images/svg-forms/purple.svg'
import {ReactComponent as GreenIcon} from 'assets/images/svg-forms/green.svg'
import clsx from 'clsx'
import {useTranslation} from 'react-i18next'
import {PlacesWithMap} from './InfoSection/components/Topics/Testing/PlacesWithMap'
import {ArrowLeftIcon} from 'assets/icons/arrow-left'
import {BurgerMenuIcon} from 'assets/icons/burger-menu'
import {DesktopHeader} from './DesktopHeader'
import {Footer} from './Footer'
import {LanguageSelect} from './LanguageSelect'
import {ConsultationModal} from './consultation/ConsultationModal'
import {Link} from 'react-router-dom'

export type Place = {
  city: string
  name?: string
  address: string
  phone: ReactNode
  coordinates: {
    lat: number
    lng: number
  }
  icon?: ReactNode
}

type PlaceBlockProps = {
  place: Place
  className?: string
  onClick?: () => void
}

export const CHECKPOINTS: Place[] = [
  {
    city: 'Tallinn',
    address: 'Rävala pst 8-1014',
    phone: '+372 58 70 6070',
    coordinates: {
      lat: 59.43393545809069,
      lng: 24.754998183441863,
    },
    icon: <BlueIcon />,
  },
  {
    city: 'Tartu',
    address: 'Kalevi 51',
    phone: '+372 58 28 09 96',
    coordinates: {
      lat: 58.3729024083588,
      lng: 26.731175211828425,
    },
    icon: <PurpleIcon />,
  },
  {
    city: 'Narva',
    address: 'Linda 4 (endine „Baltijets“), 6.',
    phone: '+372 58 70 60 70',
    coordinates: {
      lat: 59.374184454551624,
      lng: 28.1902206765406,
    },
    icon: <PinkIcon />,
  },
  {
    city: 'Johvi',
    address: 'Rakvere tn 4',
    phone: '+372 58 70 60 70',
    coordinates: {
      lat: 59.358379482456996,
      lng: 27.410500079103436,
    },
    icon: <YellowIcon />,
  },
]
export const HOSPITALS: Place[] = [
  {
    city: 'Tallinn',
    name: 'Lääne-Tallinna Keskhaigla',
    address: 'Paldiski mnt. 68',
    phone: '659 8594',
    coordinates: {
      lat: 59.430328879423804,
      lng: 24.69458729009468,
    },
    icon: <BlueIcon />,
  },
  {
    city: 'Tartu',
    name: 'Tartu Ülikooli Kliinikum',
    address: 'Ludvig Puusepa 8',
    phone: '731 9100',
    coordinates: {
      lat: 58.36953169508902,
      lng: 26.701125198683542,
    },
    icon: <PurpleIcon />,
  },
  {
    city: 'Narva',
    name: 'Narva Haigla',
    address: 'Haigla 1',
    phone: '357 1835',
    coordinates: {
      lat: 59.362308614270276,
      lng: 28.193595798676505,
    },
    icon: <PinkIcon />,
  },
  {
    city: 'Kohtla-Jarve',
    name: 'Ida-Viru Keskhaigla',
    address: 'Ravi 10',
    phone: '331 1133',
    coordinates: {
      lat: 59.40290495460291,
      lng: 27.29410607805354,
    },
    icon: <YellowIcon />,
  },
  {
    city: 'Parnu',
    name: 'Pärnu Haigla',
    address: 'Ristiku 1',
    phone: '447 3300',
    coordinates: {
      lat: 58.38758890397497,
      lng: 24.54753759981413,
    },
    icon: <GreenIcon />,
  },
]

export const PlaceBlock: FC<PlaceBlockProps> = ({place, onClick, className}) => {
  const {city, address, name, phone, icon} = place
  const {t} = useTranslation()

  return (
    <div
      className={clsx(
        'text-16-normal 1280:text-24-normal text-gray-500',
        className ?? 'mr-6 mb-6 w-[200px] 1280:w-[320px] 1280:mb-12 1280:mr-12'
      )}
    >
      <button
        onClick={onClick}
        className={clsx(onClick ? 'cursor-pointer' : 'cursor-default', 'text-left')}
      >
        <div className="flex space-x-2">
          <span className="text-16-semi 1280:text-24-semi text-black">
            {t(`cities.${city}`)}
          </span>
          <div className="flex justify-center items-center h-4 w-4 1280:h-6 1280:w-6">
            {icon}
          </div>
        </div>
        {name && (
          <>
            <span className="text-gray-400">{name}</span>
            <br />
          </>
        )}
        <span>
          <span className="text-16-medium 1280:text-24-medium text-black">
            {t('address')}:{' '}
          </span>
          <span className="whitespace-nowrap">{address}</span>
        </span>
        <br />
        <span>
          <span className="text-16-medium 1280:text-24-medium text-black">
            {t('phone')}:{' '}
          </span>
          <span className="whitespace-nowrap">{phone}</span>
        </span>
      </button>
    </div>
  )
}

export const Contacts: FC = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [isConsultationModalOpened, setIsConsultationModalOpened] = useState(false)
  const {t} = useTranslation()

  const openMenu = () => setIsMenuOpened(true)
  const closeMenu = () => setIsMenuOpened(false)

  const openConsultationModal = () => setIsConsultationModalOpened(true)

  useEffect(() => {
    if (isConsultationModalOpened) {
      document.body.classList.add('overflow-y-hidden')
    } else {
      document.body.classList.remove('overflow-y-hidden')
    }
  }, [isConsultationModalOpened])

  return (
    <>
      {isConsultationModalOpened && (
        <ConsultationModal setIsConsultationModalOpened={setIsConsultationModalOpened} />
      )}
      {isMenuOpened ? (
        <div className="absolute w-screen h-screen bg-red-secondary">
          <button
            className="absolute top-5 left-5 p-1.5 rounded bg-red-secondary"
            onClick={closeMenu}
          >
            <ArrowLeftIcon />
          </button>

          <header className="w-full pt-[100px]">
            <nav>
              <ul className="flex flex-col items-center space-y-[45px] font-montserrat text-34-bold">
                <li>
                  <Link
                    to="/"
                    className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
                  >
                    {t('start_section.msm')}
                  </Link>
                </li>

                <li>
                  <button className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80">
                    {t('menu.consult')}
                  </button>
                </li>

                <li>
                  <LanguageSelect />
                </li>
              </ul>
            </nav>
          </header>
        </div>
      ) : (
        <>
          <DesktopHeader setIsConsultationModalOpened={setIsConsultationModalOpened} />
          <button
            className="768:hidden absolute top-5 left-5 p-1.5 rounded bg-red-default"
            onClick={openMenu}
          >
            <BurgerMenuIcon />
          </button>
          <main className="h-full">
            <div className="pt-[100px] 768:pt-[150px] bg-red-default">
              <PlacesWithMap
                places={CHECKPOINTS}
                placesWrapperClassName="px-6 1024:px-[70px] 1024:py-[50px] py-5 max-w-[1200px]"
              />
            </div>
          </main>
          <Footer openConsultationModal={openConsultationModal} />
        </>
      )}
    </>
  )
}
