import {Form, Formik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {MsmField} from './MsmField'
import {sendEmail} from 'utils/sendEmail'
import {useTranslation} from 'react-i18next'
import clsx from 'clsx'

export type Props = {
  setCurrentStep: (step: number) => void
}

export const StepTwoConsultation: FC<Props> = ({setCurrentStep}) => {
  const {t} = useTranslation()

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('formik.too_short'))
      .max(50, t('formik.too_long'))
      .required(t('formik.required')),
    email: Yup.string().email(t('formik.invalid_email')).required(t('formik.required')),
    question: Yup.string()
      .min(2, t('formik.too_short'))
      .max(1000, t('formik.too_long'))
      .required(t('formik.required')),
  })

  const sendConsultationEmail = (values: Record<string, string>) => {
    setIsSendButtonDisabled(true)
    sendEmail(values, 'consultation_only')
      .then(() => setCurrentStep(3))
      .finally(() => setIsSendButtonDisabled(false))
  }

  return (
    <div className="px-[84px] pb-[50px] flex flex-col items-start">
      <span className="text-green-default text-36-bold uppercase mb-[30px]">
        {t('consultation.get_a_consultation')}
      </span>
      <Formik
        initialValues={{
          name: '',
          email: '',
          question: '',
        }}
        onSubmit={sendConsultationEmail}
        validationSchema={SignupSchema}
      >
        {({errors, touched}) => {
          return (
            <Form className="w-full">
              <div className="flex flex-col space-y-5 text-24-normal">
                <div className="flex justify-between space-x-5">
                  <MsmField
                    name="name"
                    errors={errors}
                    touched={touched}
                    placeholder={t('consultation.your_name')}
                  />
                  <MsmField
                    name="email"
                    errors={errors}
                    touched={touched}
                    placeholder={t('consultation.your_email')}
                    type="email"
                  />
                </div>
                <MsmField
                  className="h-[200px]"
                  name="question"
                  errors={errors}
                  touched={touched}
                  placeholder={t('consultation.your_question')}
                  as="textarea"
                />
              </div>

              <button
                disabled={isSendButtonDisabled}
                className={clsx(
                  'mt-[100px] w-[310px] mx-auto flex justify-center items-center py-[17px] px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80 space-x-[5px]',
                  isSendButtonDisabled && 'opacity-80'
                )}
                type="submit"
              >
                <span className="text-22-bold font-roboto">
                  {t('consultation.make_an_order')}
                </span>
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
