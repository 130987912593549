import EJ from 'assets/images/EJ.png'
import EHPV from 'assets/images/EHPV.png'
import Noit from 'assets/images/NOIT.png'
import {Instagram} from 'assets/icons/instagram'
import {Youtube} from 'assets/icons/youtube'
import {Facebook} from 'assets/icons/facebook'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

type Props = {
  openConsultationModal: () => void
}

export const Footer: FC<Props> = ({openConsultationModal}) => {
  const {t} = useTranslation()

  return (
    <footer className="flex flex-col space-y-5 768:space-y-[34px] text-white pt-10 768:pt-[77px] pb-[30px] 768:pb-[56px] text-left 768:px-[130px] bg-orange-default">
      <div className="flex justify-between items-center 768:items-start text-center 768:text-left flex-col 768:flex-row px-5 space-x-3 768:px-0 flex-wrap space-y-5">
        <div className="flex flex-col items-center 768:items-start space-y-7 768:space-y-8 max-w-[430px]">
          <p className="text-16-medium 1280:text-18-medium">{t('footer.description')}</p>
          <div className="flex justify-between flex-col 768:space-x-[38px] 768:flex-row space-y-7 768:space-y-0 768:w-[unset] w-[233px]">
            <a
              className="hover:opacity-80 w-full"
              target="blank"
              href="https://www.eltonjohnaidsfoundation.org/"
            >
              <img src={EJ} alt="Elton John Aids Foundation" />
            </a>
            <a className="hover:opacity-80 w-full" href="https://ehpv.ee/">
              <img src={EHPV} alt="Estonian network of people living with HIV" />
            </a>
          </div>
        </div>

        <div className="mt-6 768:mt-[30px]">
          <ul className="space-y-[22px] text-16-semi">
            <li>
              <button className="hover:opacity-80" onClick={openConsultationModal}>
                {t('footer.order_test')}
              </button>
            </li>
            <li>
              <button className="hover:opacity-80" onClick={openConsultationModal}>
                {t('footer.ask_support')}
              </button>
            </li>
            <li>
              <Link className="hover:opacity-80" to="/contacts">
                {t('footer.find_checkpoint')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="mt-10 768:mt-auto flex space-x-5 768:self-end">
          <a
            className="hover:opacity-80"
            target="blank"
            href="https://instagram.com/msm_checkpoint_ehpv?igshid=OGQ5ZDc2ODk2ZA=="
          >
            <Instagram />
          </a>
          <a
            className="hover:opacity-80"
            target="blank"
            href="https://youtube.com/@ehpvee?si=Oq5eYOWGADLNgeJ2"
          >
            <Youtube />
          </a>
          <a
            className="hover:opacity-80"
            target="blank"
            href="https://www.facebook.com/ehpv.ee"
          >
            <Facebook />
          </a>
        </div>
      </div>

      <div className="flex flex-col items-center 768:items-start 768:flex-row border-t border-white justify-between pt-5 768:pt-[50px] text-center 768:text-left space-y-6 768:space-y-0">
        <p className="flex flex-col space-y-0.5">
          <span className="text-14-medium">{t('footer.copyright')}</span>
          <Link
            target="blank"
            reloadDocument
            to={t('footer.privacy_policy_link')}
            className="text-14-semi"
          >
            {t('footer.privacy_policy')}
          </Link>
        </p>

        <div className="flex space-x-2 items-center">
          <img className="w-3 h-3 shrink-0" src={Noit} alt="Noit" />
          <span className="text-12-medium 768:text-18-medium">
            {t('footer.powered_by')}
          </span>
        </div>
      </div>
    </footer>
  )
}
