import {StartSection} from 'components/StartSection'
import {InfoSection} from 'components/InfoSection/InfoSection'
import {Footer} from 'components/Footer'
import {TopicsMobileDisclosure} from 'components/InfoSection/components/TopicsMobileDisclosure'
import {useEffect, useState} from 'react'
import {BurgerMenuIcon} from 'assets/icons/burger-menu'
import {ArrowLeftIcon} from 'assets/icons/arrow-left'
import {LanguageSelect} from 'components/LanguageSelect'
import {DesktopHeader} from 'components/DesktopHeader'
import {ConsultationModal} from 'components/consultation/ConsultationModal'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

export const MsmMain = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [isConsultationModalOpened, setIsConsultationModalOpened] = useState(false)
  const {t} = useTranslation()

  const openMenu = () => setIsMenuOpened(true)
  const closeMenu = () => setIsMenuOpened(false)

  const openConsultationModal = () => setIsConsultationModalOpened(true)

  useEffect(() => {
    if (isConsultationModalOpened) {
      document.body.classList.add('overflow-y-hidden')
    } else {
      document.body.classList.remove('overflow-y-hidden')
    }
  }, [isConsultationModalOpened])

  return (
    <>
      {isConsultationModalOpened && (
        <ConsultationModal setIsConsultationModalOpened={setIsConsultationModalOpened} />
      )}
      {isMenuOpened ? (
        <div className="absolute w-screen h-screen bg-red-secondary">
          <button
            className="absolute top-5 left-5 p-1.5 rounded bg-red-secondary"
            onClick={closeMenu}
          >
            <ArrowLeftIcon />
          </button>

          <header className="w-full pt-[100px]">
            <nav>
              <ul className="flex flex-col items-center space-y-[45px] font-montserrat text-34-bold">
                <li>
                  <Link
                    className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
                    to="/contacts"
                  >
                    {t('menu.contacts')}
                  </Link>
                </li>

                <li>
                  <Link
                    className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
                    to="mailto:checkpoint@ehpv.ee"
                  >
                    {t('menu.consult')}
                  </Link>
                </li>

                <li>
                  <LanguageSelect />
                </li>
              </ul>
            </nav>
          </header>
        </div>
      ) : (
        <>
          <DesktopHeader setIsConsultationModalOpened={setIsConsultationModalOpened} />
          <button
            className="768:hidden absolute top-5 left-5 p-1.5 rounded bg-red-default"
            onClick={openMenu}
          >
            <BurgerMenuIcon />
          </button>
          <main className="h-full">
            <StartSection />
            <InfoSection className="hidden 768:block" />
            <div className="768:hidden">
              <div className="bg-figures bg-contain h-[67px] bg-repeat-x" />
              <TopicsMobileDisclosure className="px-5 pb-5" />
            </div>
          </main>
          <Footer openConsultationModal={openConsultationModal} />
        </>
      )}
    </>
  )
}
