import {FC} from 'react'

type Props = {
  isChecked: boolean
  className?: string
}

export const CheckboxSVG: FC<Props> = ({isChecked = false, className}) => (
  <>
    {isChecked ? (
      <svg
        className={className}
        width="40"
        height="42"
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.1078 37.1872L6.68785 15.2865L30.2197 14.4613L29.1414 36.1966L6.1078 37.1872Z"
          stroke="#0B0B0B"
          strokeWidth="3"
        />
        <path
          d="M7.44105 10.5442L19.4051 29.5627L34.1015 2.85459"
          stroke="#65A759"
          strokeWidth="5"
          strokeLinecap="round"
        />
      </svg>
    ) : (
      <svg
        className={className}
        width="40"
        height="42"
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.1078 37.1872L6.68785 15.2865L30.2197 14.4613L29.1414 36.1966L6.1078 37.1872Z"
          stroke="#0B0B0B"
          strokeWidth="3"
        />
      </svg>
    )}
  </>
)
