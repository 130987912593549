import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import MSM_Logo from 'assets/images/MSM.png'
import {LanguageSelect} from './LanguageSelect'
import {Link} from 'react-router-dom'

type Props = {
  setIsConsultationModalOpened: (isConsultationModalOpened: boolean) => void
}

export const DesktopHeader: FC<Props> = ({setIsConsultationModalOpened}) => {
  const {t} = useTranslation()
  const openConsultationModal = () => setIsConsultationModalOpened(true)

  return (
    <header className="768:block hidden absolute top-[30px] left-[70px]">
      <nav className="flex items-center">
        <Link to="/" className="hover:opacity-80 transition-all shrink-0">
          <img className="pr-[30px]" src={MSM_Logo} alt="MSM Logo" />
        </Link>
        <ul className="flex items-center space-x-[30px] font-montserrat text-34-bold text">
          <li>
            <Link
              className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
              to="/contacts"
            >
              {t('menu.contacts')}
            </Link>
          </li>
          <li>
            <button
              onClick={openConsultationModal}
              className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
            >
              {t('menu.consult')}
            </button>
          </li>

          <li>
            <LanguageSelect className="ml-[30px] pr-4" />
          </li>
        </ul>
      </nav>
    </header>
  )
}
