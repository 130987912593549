import {Arrow} from 'assets/icons/arrow'
import {CheckboxSVG} from 'assets/icons/checkbox'
import {Field, Form, Formik} from 'formik'
import {FC} from 'react'
import {ConsultationTypeParams} from './ConsultationModal'
import {useTranslation} from 'react-i18next'

type Props = {
  setCurrentStep: (step: number) => void
  setConsultationTypeParams: (consultationTypeParams: ConsultationTypeParams) => void
}

export const StepOne: FC<Props> = ({setCurrentStep, setConsultationTypeParams}) => {
  const {t} = useTranslation()

  return (
    <div className="px-[84px] pb-[50px] flex flex-col items-start">
      <span className="text-green-default text-36-bold uppercase mb-[50px]">
        {t('consultation.how_can_we_assist_you')}
      </span>
      <Formik
        initialValues={{
          test: false,
          consultation: false,
        }}
        onSubmit={values => {
          setConsultationTypeParams(values)
          setCurrentStep(2)
        }}
      >
        {({values, setFieldValue}) => {
          return (
            <Form className="w-full">
              <div className="flex flex-col items-start space-y-10 text-24-normal">
                <label htmlFor="test">
                  <Field id="test" name="test">
                    {/* @ts-ignore */}
                    {({field}) => (
                      <div className="flex items-center space-x-5">
                        <button
                          type="button"
                          onClick={() => setFieldValue('test', !values.test)}
                        >
                          <CheckboxSVG isChecked={field.value} />
                        </button>
                        <span>
                          {t('consultation.i_d_like_to_order_a_rapid_hiv_test_by_mail')}
                        </span>
                      </div>
                    )}
                  </Field>
                </label>
                <label htmlFor="consultation">
                  <Field id="consultation" name="consultation">
                    {/* @ts-ignore */}
                    {({field}) => (
                      <div className="flex items-center space-x-5">
                        <button
                          type="button"
                          onClick={() =>
                            setFieldValue('consultation', !values.consultation)
                          }
                        >
                          <CheckboxSVG isChecked={field.value} />
                        </button>
                        <span>
                          {t(
                            'consultation.i_would_like_to_receive_an_online_consultation'
                          )}
                        </span>
                      </div>
                    )}
                  </Field>
                </label>
              </div>

              <button
                className="mt-[100px] w-[310px] mx-auto flex justify-center items-center py-[17px] px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80 space-x-[5px]"
                type="submit"
              >
                <span className="text-22-bold font-roboto">{t('consultation.next')}</span>
                <Arrow direction="right" />
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
